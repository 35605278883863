<template>
  <div :style="getElementStyle">
        <!-- Below code is for Before Field View -->
    <div v-if="!isDefalutPos">
      <div style="display: flex; align-items: unset;">
        <label for="text" style="white-space: nowrap; margin-right: 5px;">{{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold; margin-right: 5px;"
        >
          *
        </span>
        <span> <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i></span>
        <span v-if="data.description" style="margin-right: 5px;">
          <i
            class="el-icon-info"
            :title="data.description"
          ></i>
        </span>
        <template v-if="data.input_type == 'MULTI_LINE_TEXT' || data.input_type == 'PAYMENT_VARIABLE'">
            <el-input
              type="textarea"
              :rows="data.validations.rows"
              :style="{'text-align': inputTextAlignment(),...getStyle}"
              resize="none"
              :placeholder="data.placeholder"
              :suffix-icon="data.properties.el_icon"
              v-model="selectedValue"
            ></el-input>
          </template>
          <template v-if="data.input_type == 'RICH_TEXT'">
            <TextEditor v-if="!isDataTableField" :data="selectedValue"></TextEditor>
            <el-link v-else>Text Editor</el-link>
          </template>
      </div>
      <div>
        <span
        v-if="isActive || isDataTableField"
        class="setting-icon"
      >
        <i
          class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </span>
      </div>
    </div>
            <!-- Below code is for Top Field View -->

    <el-row align="middle" v-else>
      <el-col v-if="!isInDataTable" :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24" ref="colRef" :style="computedStyles">
        <label for="text">{{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>

      <el-col :span="showLabel && isDefalutPos ? 4 : 0">
        <!-- <span v-if="data.description">
          <i
            class="el-icon-info"
            :title="data.description"
          ></i>
        </span> -->
      </el-col>
      <el-row
        v-if="isActive || isDataTableField"
        class="setting-icon"
      >
        <i
          class="el-icon-s-tools"
          @click="openSettings"
        ></i>
      </el-row>
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24">
        <div>
          <template v-if="data.input_type == 'MULTI_LINE_TEXT' || data.input_type == 'PAYMENT_VARIABLE'">
            <el-input
              type="textarea"
              :rows="data.validations.rows"
              :style="{'text-align': inputTextAlignment(),...getStyle}"
              resize="none"
              :placeholder="data.placeholder"
              :suffix-icon="data.properties.el_icon"
              v-model="selectedValue"
              :class="{ datatable: isDataTableField }"
            ></el-input>
          </template>
          <template v-if="data.input_type == 'RICH_TEXT'">
            <ckeditor :data="selectedValue" v-if="!isDataTableField" :config="editorConfig"></ckeditor>
            <el-link type="primary" v-else>CK Text Editor</el-link>
          </template>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import templateBuilderHelper from "@/mixins/templateBuilderHelper";
import TextEditor from "../../contentBlocks/TextEditor";
import FieldStylesHelper from "@/mixins/FieldStylesHelper"  

import { ckEditorConfig } from "@/assets/data/ckeditor_config.ts";
import { mapGetters } from "vuex";
// import { bus } from "../../../main";
export default {
  name: "MultiLineTextView",
  components: {
    TextEditor
  },
  props: ["data", "value", "isActive", "isDataTableField","isInDataTable"],
  mixins: [
    templateBuilderHelper,
    FieldStylesHelper
  ],

  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = 'flex';
      styles['marginBottom'] = '7px';

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
    getStyle() {
      // return()=>{
      let inputStyle = "";
      inputStyle += `height:${this.data.height - 30}px`;
      inputStyle += ";";
      if (this.data.styles) {
        inputStyle += this.data.styles.input_background
          ? `background-color: ${this.data.styles.input_background}`
          : "";
      }

      inputStyle += ";";
      // this.calRows();
      return inputStyle;
      // }
    },
  },
  data() {
    return {
      validations: [],
      // options: [],
      // isList: false,
      isDefalutPosq: true,
      showLabel: true,
      inputTextAlignmentValue: 'left',
      editorConfig : ckEditorConfig,
    };
  },
  mounted() {
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }
    this.options = this.data.options || [];
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    this.editorConfig.height = this.data.height-170;
  },

  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    inputTextAlignment() {
    return this.data.styles && this.data.styles.inputTextAlignments
      ? this.data.styles.inputTextAlignments
      : 'left'; // Set a default value if not provided
    },

    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );
      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "MULTI_LINE_TEXT") {
          this.data.selectedValue = this.getGlobalVariableById.value;
        } else {
          // console.log("tst",this.trow);
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    }
  },
  watch: {
    isActive: {
      immediate: true,
      handler() {
        //console.log(val, oldVal);
      }
    },
    'data.styles.inputTextAlignments': {
    handler(newVal) {
      // Whenever inputTextAlignments changes, update the computed property.
      this.inputTextAlignmentValue = newVal;
    },
    immediate: true // This will trigger the handler immediately when the component is created
    },
    'data.height': function (newHeight) {
      // Update the editorConfig height whenever data.height changes
      this.editorConfig.height = newHeight;
    },
  }
};
</script>